import { useContext } from "react";
import ProofContext from "../context/proof-data";
import HeadingImage from "./assets/header.jpg";

function Header () {
  const order = useContext(ProofContext);
  if (order.ProofDataResponses.length > 0){
  return(
  	<div className="col-md-2">
      <img src={HeadingImage} alt="proof now" className="header-image"></img>
  	   <br/>
        <div className="row">
            <div className="col-md-12">
              <br/>
              <br/>
      	      <a href={"../proofs/" + order.ProofDataResponses[0].FileName} target="_blank" rel="noreferrer">
                <h3>View Proof #{order.ProofDataResponses.length}</h3>
              </a>
            </div>
        </div>
     </div>
  )
} else {
	return(<div className="col-md-2">Loading...</div>)
}
};


export default Header;
