import { useContext } from "react";
import ProofContext from "../context/proof-data";

const FeedbackComplete = () => {
  const order = useContext(ProofContext);
  return (
    <form className="col-md-4 form-look">
        <h3>Proof Slip</h3>
        <br/>
        <p className="alert alert-danger" role="alert">A response has already been submitted.</p>
        <div className="row">
          <div className="col-md-12">
            <label><strong>These questions were posted:</strong></label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p>{order.ProofDataResponses[0].ProofQuestions}</p>
          </div>
        </div>
        <br/>
          <div className="row">
            <div className="col-md-4">
              <label><strong>Response:</strong></label>
            </div>
            <div className="col-md-8">
              <p>{order.ProofDataResponses[0].ProofResponse}</p>
            </div>
          </div>
        <br/>
        <div className="row">
          <div className="col-md-4">
            <label><strong>Comments:</strong></label>
          </div>
          <div className="col-md-8">
            <p>{order.ProofDataResponses[0].ResponseComments}</p>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-4">
            <label><strong>Submitted by:</strong></label>
          </div>
          <div className="col-md-8">
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <label>Name:</label>
          </div>
          <div className="col-md-7">
            <p>{order.ProofDataResponses[0].ResponseName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <label>Company:</label>
          </div>
          <div className="col-md-7">
            <p>{order.ProofDataResponses[0].ResponseCompany}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <label>Date:</label>
          </div>
          <div className="col-md-7">
            <p>{order.ProofDataResponses[0].ResponseDate}</p>
          </div>
        </div>
    </form>
  );
};

export default FeedbackComplete;
