import MainPage from "./main-page";
import ProofContext from "../context/proof-data";  
import ProofData from "../hooks/proof-data";       

function MainPageContainer () {
  return(
    <ProofContext.Provider value={ProofData()}>
        <MainPage />
    </ProofContext.Provider>
  )
}

export default MainPageContainer;