import { useContext } from "react";
import Feedback from "./feedback";
import OrderInfo from "./order-info";
import Expired from "./expired";
import Header from "./header";
import ProofHistory from "./proof-history";
import ProofContext from "../context/proof-data";         

function MainPage () {
  let order = useContext(ProofContext);
  if (order &&
      order.ProofDataResponses &&
      order.ProofDataResponses.length > 0){
      if (order.IsError || order.ProofDataResponses[0].Status === "Waiting for proofbot"){
        return (
          <>
          <div className="row">
            <Expired />
          </div>
          </>
        )
      } else {
        return(
          <>
            <br/>
            <br/>
            <div className="row">
              <Header />
              <Feedback />
              <OrderInfo />
            </div>
            <br/>
            <br/>
            <div className="row">
              <ProofHistory />
            </div>
          </>

      )
    }
  } else {
    return(
      <div>Loading...</div>
    )
  }
}

export default MainPage;
