import { useContext } from "react";
import ProofContext from "../context/proof-data";

function OrderInfo() {
  const order = useContext(ProofContext);

  if (order.ProofDataResponses.length > 0){
    const orderLine = order.ProofDataResponses[0].LineNumber < 10
                      ? '0' + order.ProofDataResponses[0].LineNumber
                      : order.ProofDataResponses[0].LineNumber.toString();
    return(
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-11">
            <h3>Order Information</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <p><strong>Distributor: </strong></p>
          </div>
          <div className="col-md-4">
            <p>{order.ProofDataResponses[0].Distributor}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <p><strong>PO #: </strong></p>
          </div>
          <div className="col-md-4">
            <p>{order.ProofDataResponses[0].PoNumber}</p>
          </div>
        </div>
        <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-4">
                <p><strong>Job Number: </strong></p>
            </div>
            <div className="col-md-4">
                <p>{order.ProofDataResponses[0].OrderNumber} {orderLine}</p>
            </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <p><strong>Proof Date: </strong></p>
          </div>
          <div className="col-md-4">
            <p>{order.ProofDataResponses[0].ProofDate}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <p><strong>Item: </strong></p>
          </div>
          <div className="col-md-4">
            <p>{order.ProofDataResponses[0].LineDesc}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <p><strong>Quantity: </strong></p>
          </div>
          <div className="col-md-4">
            <p>{order.ProofDataResponses[0].Quantity}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <p><strong>Size: </strong></p>
          </div>
          <div className="col-md-4">
            <p>{order.ProofDataResponses[0].Size}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <p><strong>Materials: </strong></p>
          </div>
          <div className="col-md-4">
            <p>{order.ProofDataResponses[0].Materials}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <p><strong>Colors: </strong></p>
          </div>
          <div className="col-md-4">
            <p>{order.ProofDataResponses[0].Colors}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-11">
            <p><strong>Your proof can be viewed in a separate browser window.&nbsp; 
              <a href={"../proofs/" + order.ProofDataResponses[0].FileName} target="_blank" rel="noreferrer">
              Click here to view your proof.
              </a>
            </strong></p>
          </div>
        </div>
    </div>
    );
    } else {
      return(<div className="col-md-6">Loading...</div>)
    }
}
export default OrderInfo
