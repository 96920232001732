import HeadingImage from "./assets/header.jpg"; 
import { useContext } from "react";
import ProofContext from "../context/proof-data";

function Expired() {
  const order = useContext(ProofContext);
  if (order.ProofDataResponses.length > 0){
    const orderLine = order.ProofDataResponses[0].LineNumber < 10
                      ? '0' + order.ProofDataResponses[0].LineNumber
                      : order.ProofDataResponses[0].LineNumber.toString();
    const orderMessage = order.ProofDataResponses[0].Status === "Waiting for proofbot" 
                         ? 'Your proof file should be ready shortly'
                         : order.ErrorMessage
    return(
      <>
          <div className="col-md-2">
            <br/>
            <br/>
            <img src={HeadingImage} alt="proof now" className="header-image"></img>
          </div>
          <div className="col-md-5">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h2>{orderMessage}</h2>
          </div>
          <div className="col-md-5">
            <br/>
            <br/>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4">
                <p><strong>Distributor: </strong></p>
              </div>
              <div className="col-md-4">
                <p>{order.ProofDataResponses[0].Distributor}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4">
                <p><strong>PO #: </strong></p>
              </div>
              <div className="col-md-4">
                <p>{order.ProofDataResponses[0].PoNumber}</p>
              </div>
            </div>
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-4">
                    <p><strong>Job Number: </strong></p>
                </div>
                <div className="col-md-4">
                    <p>{order.ProofDataResponses[0].OrderNumber} {orderLine}</p>
                </div>
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4">
                <p><strong>Proof Date: </strong></p>
              </div>
              <div className="col-md-4">
                <p>{order.ProofDataResponses[0].ProofDate}</p>
              </div>
            </div>
          </div>
      </>
      )
    }
 }

 export default Expired;