  import { useState, useEffect } from "react";
  import { useParams } from "react-router-dom";
  const ProofData = () => {
	  let  { id } = useParams();
	  const [order, setOrder] = useState({"ErrorMessage": "not loaded", "IsError": false, "ProofDataResponses": []});
	  useEffect(() => {
	 	const service_location = () =>{
		  	return (process.env.REACT_APP_DATASERVICE?
		  			process.env.REACT_APP_DATASERVICE:
		  			"http://proofnowdev.gill-line.com");
		  } 
		fetch(service_location() + '/ProofNow.svc/web/ProofNow/Get/' + id)
		  .then(res => res.json())
		  .then(
			(result) => {
			  result.ProofDataResponses.forEach(item => {
				item.ResponseDate = new Date(parseInt(item.ResponseDate.replaceAll('/','').replace('Date(','').replace(')','')));
				item.ResponseDate = item.ResponseDate.toLocaleDateString() + ' ' + item.ResponseDate.toLocaleTimeString();
				item.ProofDate = new Date(parseInt(item.ProofDate.replaceAll('/','').replace('Date(','').replace(')','')));
				item.ProofDate = item.ProofDate.toLocaleDateString();
			  });
			  setOrder(result);
			},
			(error) => {
			  console.log(error);
			}
		  )
	  }, [id])
	 return order;
  };

  export default ProofData;
