import { useContext } from "react";
import FeedbackForm from "./feedback-form";
import FeedbackComplete from "./feedback-complete";
import ProofContext from "../context/proof-data";         

function Feedback () {
  const order = useContext(ProofContext);
  if (order && 
      order.ProofDataResponses && 
      order.ProofDataResponses.length > 0 && 
      order.ProofDataResponses[0].Status === "Proof Responded"){
    return(<FeedbackComplete />)
  } else {
    return(<FeedbackForm />)
  }
  
}

export default Feedback;