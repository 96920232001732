import HeadingImage from "./assets/header.jpg";

function AltHeader () {
  return(
    <>
      <br/>
      <br/>
      <div className="row">
          <div className="col-md-2">
            <img src={HeadingImage} alt="proof now" className="header-image"></img>
          </div>
        <div className="col-md-10">
          <br/>
          <strong>
            <a href="/#">Welcome to MyProofNow.com</a>
            <p>To view your proof on this site, please click on the link in the email sent to you.  Thank you.</p>
          </strong>
        </div>
      </div>
    </>
  )
}

export default AltHeader;