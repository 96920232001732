import HeadingImage from "./assets/header.jpg"; 

function ThankYou(props) {
  return(
    <>
      <br/>
      <br/>
      <div className="row">
        <div className="col-md-2">
          <img src={HeadingImage} alt="proof now" className="header-image"></img>
        </div>
        <div className="col-md-10">
          <br/>
          <strong>
            <p><span>Thank you for using our online proofing service.
            {props.location.state.submitResult.IsError === true &&
                <div class="alert alert-warning" role="alert">{props.location.state.submitResult.ErrorMessage}</div>
            }
            {props.location.state.submitResult.IsError === false && props.location.state.proofresponse === 'OK to Print' &&
                <span>&nbsp;We will get the order into the system as soon as possible.</span>
            }
            {props.location.state.submitResult.IsError === false && props.location.state.proofresponse === 'Make Changes then Print' &&
               <span>&nbsp;We will make the changes and get the order into the system as soon as possible.</span>
            }
            {props.location.state.submitResult.IsError === false && props.location.state.proofresponse === 'Make Changes then Reproof' &&
                <span>&nbsp;We will make the changes and send you another proof</span>
            }
            </span></p>

          </strong>
        </div>
      </div>
    </>
  )
 }

 export default ThankYou;
