import { useContext } from "react";
import ProofContext from "../context/proof-data";

function ProofHistory(){
  const order = useContext(ProofContext);
  if (order.ProofDataResponses.length < 2){
    return(<div><h4>No history</h4></div>);
  } else if (order.ProofDataResponses.length > 0) {
    return (
      <>
        <h4>Proof History</h4>
          {order.ProofDataResponses.slice(1).map((item, index) => (
            <div key={index}>
              <div className="row">
                <div className="col-md-3">
                  <a href={"../proofs/" + item.FileName} target="_blank" rel="noreferrer">Proof #{item.ProofNumber}</a>
                </div>
                <div className="col-md-9">
                  Response: {item.ProofResponse}
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                </div>
                <div className="col-md-9">
                  <div dangerouslySetInnerHTML={{ __html: item.ResponseComments}}></div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                </div>
                <div className="col-md-9">
                  Submitted by: {item.ResponseName} on {item.ResponseDate}
                </div>
              </div>
              <span><br/></span>
            </div>
          ))}
    </>
    );
  } else {
    return(<div>Loading...</div>);
  }
}

export default ProofHistory