import { useState, useContext } from "react";
import { ProofContext } from "../context/proof-data";
import { Redirect, useParams } from "react-router-dom";

const FeedbackForm = () => {
  let  { id } = useParams();
  let order = useContext(ProofContext);
  const [redirect, setRedirect] = useState(false);
  const [submitResult, setSubmitResult] = useState({});
  const [feedbackInfo, setFeedbackInfo] = useState({
    comments: "",
    name: "",
    company: "",
    questionsAnswered: false
  });

  const onChange = (e) => {
    setFeedbackInfo({ ...feedbackInfo, [e.target.id]: e.target.value });
  };
  const onChangeReproof = (e) => {
    setFeedbackInfo({ ...feedbackInfo, [e.target.id]: e.target.value });
    alert('Additional charges may occur if you have more than 2 proofs.');
  };
  const onCheckChange = (e) => {
    setFeedbackInfo({ ...feedbackInfo, [e.target.id]: !feedbackInfo.questionsAnswered });
  };
  const service_location = () =>{
    return (process.env.REACT_APP_DATASERVICE?
        process.env.REACT_APP_DATASERVICE:
        "http://proofnowdev.gill-line.com");
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let data = {
        "LineNumber":order.ProofDataResponses[0].LineNumber,
        "OrderNumber":order.ProofDataResponses[0].OrderNumber,
        "ProofNumber":order.ProofDataResponses[0].ProofNumber,
        "ResponseComments":feedbackInfo.comments,
        "ProofResponse": feedbackInfo.proofresponse,
        "ResponseCompany":feedbackInfo.company,
        "ResponseName": feedbackInfo.name,
        "UniqueId": id
    }

    fetch(service_location() + '/ProofNow.svc/web/ProofNow/Update', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
    .then(res => res.json())
    .then(
      (result) => {
        setSubmitResult(result);
        setRedirect(true);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  if (redirect){
    return(
      <Redirect to={{pathname: "/thankyou", state: {proofresponse: feedbackInfo.proofresponse, submitResult: submitResult}}}  />
      )
  } else {
    return (
      <form className="col-md-4">
          <h3>Proof Feedback</h3>
          <br/>
          <p>Please check copy and spelling, design layout, and color placement.</p>
            <div className="row">
              <div className="col-md-4">
                <label>Approval:</label>
              </div>
              <div className="col-md-8">
                  <div className="form-check">
                    <input type="radio" value="OK to Print" id="proofresponse" name="proofresponse" onChange={onChange} checked={feedbackInfo.proofresponse === "OK to Print"}/> OK to Print
                  </div>
                  <div className="form-check">
                    <input type="radio" value="Make Changes then Print" id="proofresponse" name="proofresponse" onChange={onChange} checked={feedbackInfo.proofresponse === "Make Changes then Print"} /> Make Changes then Print
                  </div>
                  <div className="form-check">
                    <input type="radio" value="Make Changes then Reproof" id="proofresponse" name="proofresponse" onChange={onChangeReproof} checked={feedbackInfo.proofresponse === "Make Changes then Reproof"}/> Make Changes then Reproof
                  </div>
              </div>
            </div>
          <br/>
          <p>Additional charges may occur if you have 3 or more proofs.</p>
          <div className={order && order.ProofDataResponses.length > 0 && order.ProofDataResponses[0].ProofQuestions && order.ProofDataResponses[0].ProofQuestions.length > 0?"d-inline":"d-none"}>
              <p><strong>Please Respond:</strong><br/>
              <span className="text-danger">{order && order.ProofDataResponses.length > 0 && order.ProofDataResponses[0].ProofQuestions?order.ProofDataResponses[0].ProofQuestions:""}</span></p>
          </div>
          <div className="form-group">
            <label htmlFor="comments">Comments</label>
            <textarea
              type="text"
              className="form-control"
              placeholder="Comments"
              id="comments"
              name="comments"
              value={feedbackInfo.comments}
              onChange={onChange}
            />
            <div className={order && order.ProofDataResponses.length > 0 && order.ProofDataResponses[0].ProofQuestions && order.ProofDataResponses[0].ProofQuestions.length > 0?"d-inline":"d-none"}>
              <p>
              <input type="checkbox" name="questionsAnswered" id="questionsAnswered" onChange={onCheckChange} checked={feedbackInfo.questionsAnswered} />
              &nbsp;I have responded to the questions above in red.
              </p>
           </div>
          </div>
          <br/>
          <h4>Approved By</h4>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              className="form-control"
              placeholder="Approved by Name"
              value={feedbackInfo.name}
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="remarks">Company</label>
            <input
              type="text"
              id="company"
              className="form-control"
              placeholder="Approved by Company"
              value={feedbackInfo.company}
              onChange={onChange}
            />
          </div>
          <button
            className="btn btn-primary mt-2"
            disabled={!feedbackInfo.name || !feedbackInfo.company || !feedbackInfo.proofresponse || (order.ProofDataResponses[0].ProofQuestions.length > 0 && !feedbackInfo.questionsAnswered)}
            onClick={onSubmit}
          >
            Submit
          </button>
      </form>
    );
  };
};

export default FeedbackForm;
