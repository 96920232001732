import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./main-page.css";
import MainPageContainer from "./main-page-container"; 
import CacheBuster from 'react-cache-buster';
import { version } from '../../package.json';
import AltHeader from "./alt-header";
import ThankYou from "./thank-you"; 


function App() {
	const isProduction = process.env.NODE_ENV === 'production';
	return (
		<CacheBuster
			currentVersion={version}
			isEnabled={isProduction} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			loadingComponent={<div>Loading...</div>} //If not pass, nothing appears at the time of new version check.
		>
      <Router>
        <Switch>
          <Route exact path="/proof/:id" render={props => { 
              return(
                <div className="container">
                  <MainPageContainer {...props} />
                </div>
              );
            }}
          />
          <Route exact path="/">
            <div className="container">
              <AltHeader />
            </div>
          </Route>
          <Route exact path="/thankyou" render={props => {
              return(
                <div className="container">
                  <ThankYou {...props} />
                </div>
              );
            }}
          />
        </Switch>
    </Router>
	</CacheBuster>
 )
}

export default App;
